import { render, staticRenderFns } from "./InviteFriends.vue?vue&type=template&id=06564082&scoped=true"
import script from "./InviteFriends.vue?vue&type=script&lang=js"
export * from "./InviteFriends.vue?vue&type=script&lang=js"
import style0 from "styles/views/account/inviteFriends.css?vue&type=style&index=0&id=06564082&prod&scoped=true&lang=css&external"
import style1 from "./InviteFriends.vue?vue&type=style&index=1&id=06564082&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06564082",
  null
  
)

export default component.exports